import React from 'react';
import Layout from '../../components/layout';
import Transition from '../../components/transition';
import Header from '../../components/header';
import PublishedDoya from '../../components/published-do-ya';
import PublishedKyotocityKyoceraMuseum from '../../components/published-kyotocity-kyocera-museum';
import PublishedNishiri from '../../components/published-nishiri';
import PublishedKyotoUniqlo from '../../components/published-kyoto-uniqlo';
import PublishedKex from '../../components/published-kex';
import PublishedMaimai from '../../components/published-maimai';
import PublishedParasophia2015 from '../../components/published-parasophia-2015';
import PublishedSeikaGenzine from '../../components/published-seika-genzine';
import PublishedKyoto404 from '../../components/published-kyoto-404';
import PublishedArtWalkKyoto from '../../components/published-art-walk-kyoto';
import PublishedFoodDesign from '../../components/published-food-design';
import PublishedSavvy from '../../components/published-savvy';
import PublishedSeesaw from '../../components/published-seesaw';
import PublishedPomagazine from '../../components/published-pomagazine';
import PublishedUro from '../../components/published-uro';
import PublishedFelissimo from '../../components/published-felissimo';
import PublishedKonanOneDay from '../../components/published-konan-one-day';
import PublishedLmagazineKyotobon from '../../components/published-lmagazine-kyotobon';
import PublishedHagiso from '../../components/published-hagiso';
// import Instagram from '../../components/instagram';
import Footer from '../../components/footer';
import { InitTransition } from '../../components/init-transition';
import InitAnimation from '../../components/init-animation';
import Style from '../../styles/works/works.module.scss';

class Works extends React.Component {
  constructor() {
    super();
    this.child = React.createRef();
    this.initAnimation = new InitAnimation();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const isWorks = 'Works';
    const headingWorks = document.querySelector('.init_a_headingWorks');
    const introWorks = document.querySelector('.init_a_introWorks');
    setTimeout(() => {
      this.initAnimation.heading(headingWorks, isWorks);
      this.initAnimation.intro(introWorks);
    }, 1000);
  }

  render() {
    const transition = {
      InitTransition,
      animation: () => {
        this.child.current.exitAnimation(transition.InitTransition.length);
      },
    };

    return (
      <Layout>
        <Transition ref={this.child}>
          <Header transition={transition} current="works" />
          <main className={`works ${Style.works}`}>
            <section className={`top ${Style.top}`}>
              <h1 className="heading">
                <span className="text textWorks">Works<br className="spTabHDisBlock" /> & Projects</span>
                <span className="clip clipWorks init_a_headingWorks">Works<br className="spTabHDisBlock" /> & Projects</span>
              </h1>
              <p className="intro init_a_introWorks">
                新旧メディアのいずれに偏ることなく
                <br className="pcDisBlock" />
                最適な「乗り物」にのせて届けます。
              </p>
            </section>
            <section className={`sectionContent ${Style.worksProjects}`}>
              <PublishedUro transition={transition} />
              <PublishedKex transition={transition} />
              <PublishedFelissimo transition={transition} />
              <PublishedKonanOneDay transition={transition} />
              <PublishedLmagazineKyotobon transition={transition} />
              <PublishedHagiso transition={transition} />
              <PublishedPomagazine transition={transition} />
              <PublishedArtWalkKyoto transition={transition} />
              <PublishedDoya transition={transition} />
              <PublishedSavvy transition={transition} />
              <PublishedSeesaw transition={transition} />
              <PublishedFoodDesign transition={transition} />
              <PublishedKyotocityKyoceraMuseum transition={transition} />
              <PublishedNishiri transition={transition} />
              <PublishedKyotoUniqlo transition={transition} />
              <PublishedMaimai transition={transition} />
              <PublishedParasophia2015 transition={transition} />
              <PublishedSeikaGenzine transition={transition} />
              <PublishedKyoto404 transition={transition} />
            </section>
            {/*<Instagram />*/}
          </main>
          <Footer transition={transition} />
        </Transition>
      </Layout>
    );
  }
}

export default Works;
